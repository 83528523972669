import { Button, Grid, Typography } from "@mui/material";
import CustomStyledButton from "./CustomStyledButton";

// import addIcon from "../../assets/images/add-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import addIconWhite from "../../assets/images/add-icon-white.svg";
import downloadIcon from "../../assets/images/export-product.svg";
import downloadIconWhite from "../../assets/images/export-product-white.svg";
import { AvailableForms, AvailableTabs } from "./productTable/tableData";
import MDButton from "components/MDButton";
import IosShareIcon from "@mui/icons-material/IosShare";

export default function ProductNavbar({ setForm, selectedTab }) {
  const url =
    process.env.REACT_APP_NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_BASE_URL}/product-service`
      : `/product-service`;

  const getActiveTabExportURL = () => {
    switch (selectedTab) {
      case AvailableTabs.singleProduct:
        `${url}/variants/export`;
      case AvailableTabs.nodeWiseBulkProduct:
        `${url}/bulk-product/export`;
      case AvailableTabs.bulkProduct:
        `${url}/super-admin-bulk-product/export`;
      default:
        "/variants/export";
    }
  };

  const navbarButtons = [
    {
      key: "node-wise-bulk-product",
      lable: "Node Wise Bulk Product Upload",
      onClick: () => {
        setForm(AvailableForms.nodeWiseBulkProduct);
      },
    },
    {
      key: "mass-bulk-product",
      lable: "Mass Bulk Product Upload",
      onClick: () => {
        setForm(AvailableForms.bulkProduct);
      },
    },
    {
      key: "bulk-price",
      lable: "Bulk Price Update",
      onClick: () => {
        setForm(AvailableForms.bulkPriceUpload);
      },
    },
    {
      key: "export",
      lable: "Export Table Rows",
      onClick: () => {
        gridRef.current?.api.exportDataAsCsv();
      },
      startIcon: <IosShareIcon />,
      hoverIcon: <IosShareIcon />,
    },
  ];

  return (
    <Grid container justifyContent={"space-between"} gap={1}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography
          sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }}
          ml={2}
        >
          Products
        </Typography>
      </Grid>
      <Grid>
        {navbarButtons.map((btn) => {
          return (
            <MDButton
              style={{ marginLeft: "8px" }}
              circular={true}
              variant={"contained"}
              color={"black"}
              {...btn}
              startIcon={btn.startIcon}
            >
              {btn.lable}
            </MDButton>
          );
        })}

        <Button
          startIcon={<IosShareIcon />}
          href={getActiveTabExportURL()}
          component={"a"}
          download={"export.xlsx"}
        >
          Export
        </Button>
      </Grid>
    </Grid>
  );
}

import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box, TablePagination, IconButton, Icon } from '@mui/material';
import dayjs from 'dayjs';
import {  LIST_ALL_FIXTURES, REMOVE_FIXTURE, UPDATE_FIXTURE } from '../../services/advertisment-service';
import { vmServiceClient } from 'graphql/client';
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from 'react';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DropDownSelectionContext, useGridContext } from ".";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { getFlatIdMap } from "../device-Master/utils";
import AgGrid from "components/MDGrid";
import { createSortByFromOrderModel } from "layouts/cutsomer/utils";
import { assetTypeOptions } from "layouts/stores/StoreOffer";
import { getFilteredLocationOptions, getFilteredSubLocationOptions } from "./utils";
import { DropdownFilterComponent } from "components/MDGrid/custom-dropdown-filter";

const columnDefs = (
  handleDeleteClick,
  storeIdMap,
  stores,
  locations,
  subLocations,
  assetTypes,
  externalGridRef
) => [
  {
    field: "code",
    headerName: "Fixture Code",
    minWidth: 180,
    headerAlign: "center",
  },
  {
    field: "fixtureName",
    headerName: "Fixture name",
    minWidth: 200,
    headerAlign: "center",
  },
  {
    field: "fixtureType",
    headerName: "Fixture Type",
    minWidth: 200,
    filter: "agSetColumnFilter",
    filterParams: {
      // values: ['WALL', 'FLOOR','CROSSWALL','BROWSER','TABLE', 'PILAR BROAD','OTHER'],
      values: assetTypeOptions.map((ato) => ato.value),
    },
    getFilterObj: (filter) => {
      // console.log(value,"VALUE");
      return { fixtureType: filter.values };
    },
  },
  // {
  //   field: "fixtureSize",
  //   headerName: "Fixture Size",
  //   minWidth: 250,
  // },
  {
    field: "storeId",
    headerName: "Store Name",
    minWidth: 200,
    valueGetter: (params) => storeIdMap[params.data.storeId]?.name,
    getFilterObj: (storeFilter) => {
      return {
        storeId: storeFilter.filter || undefined,
      };
    },
    filter:DropdownFilterComponent,
    floatingFilterComponent: DropdownFilterComponent,
    floatingFilterComponentParams: {
        options: stores.map((store)=>({label:store.name, value:store.id})),
        label:'Select Store',
        id:'select-store'
    },
  },
  {
    field: "location",
    headerName: "Location",
    minWidth: 200,
    valueGetter: (params) =>
      storeIdMap[params.data.locationId]?.division?.title,
    getFilterObj: (locationFilter) => {
      return {
        locationId: locationFilter.filter,
      };
    },
    // filter: "agSetColumnFilter",
    // filterParams: {
    //   values: getFilteredLocations(locations, externalGridRef),
    //   valueFormatter: (params) => storeIdMap[params.value].division?.title,
    // },
    filter:DropdownFilterComponent,
    floatingFilterComponent: DropdownFilterComponent,
    floatingFilterComponentParams: {
        options: getFilteredLocationOptions(locations, externalGridRef),
        label:'Select Location',
        id:'select-location'
    },
  },
  {
    field: "subLocation",
    headerName: "Sub-Location",
    minWidth: 200,
    valueGetter: (params) => storeIdMap[params.data.subLocationId]?.name,
    getFilterObj: (subLocationFilter) => {
      return {
        subLocationId: subLocationFilter.filter,
      };
    },
    // filter: "agSetColumnFilter",
    // filterParams: {
    //   values: getFilteredSubLocationOptions(subLocations, externalGridRef),
    //   valueFormatter: (params) => storeIdMap[params.value].name,
    // },
    filter:DropdownFilterComponent,
    floatingFilterComponent: DropdownFilterComponent,
    floatingFilterComponentParams: {
        options: getFilteredSubLocationOptions(subLocations, externalGridRef),
        label:'Select Sub Location',
        id:'select-sublocation'
    },
  },
  {
    field: "subLocationTypeId",
    headerName: "Batch Code",
    minWidth: 200,
    getFilterObj: (filter) => {
      return {
        subLocationTypeId: filter.values,
      };
    },
    filter: "agSetColumnFilter",
    filterParams: {
      values: assetTypes
      .filter((assetType) =>{
        const selectedSubLocation =  externalGridRef.current?.getColumnFilterModel("subLocation")?.filter
        return selectedSubLocation && !assetType.isDeleted &&
        selectedSubLocation==assetType.parentId
      }
      )
      .map((assetType) => assetType.batchCode),
      // valueFormatter: (params) => storeIdMap[params.value].batchCode,
    },
    // allowSearch:true
  },
  {
    field: "assetTypeCode",
    headerName: "Asset Type Code",
    minWidth: 200,
    filter: "agSetColumnFilter",
    filterParams: {
      values: assetTypes
      .filter((assetType) =>{
        const batchCodeList =  externalGridRef.current?.getColumnFilterModel("subLocationTypeId")?.values
        || externalGridRef.current?.getColumn('subLocationTypeId')?.colDef?.filterParams?.values
        || []
        return !assetType.isDeleted &&
        batchCodeList.includes(assetType.batchCode)
      })
      .map((assetType) => assetType.code),
      // valueFormatter: (params) => storeIdMap[params.value].batchCode,
    },
  },
  {
    field: "opsCode",
    headerName: "Ops Fixture Code",
    minWidth: 200,
  },
  {
    field: "businessCode",
    headerName: "Business Code",
    minWidth: 200,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    minWidth: 180,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0]),
        );
    
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
    
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
    
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      minValidYear: 2023,
      inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      filterOptions: ['inRange'],
      defaultOption: 'inRange',
      maxNumConditions: 1,
      inRangeInclusive:true
    },
    getFilterObj: (value) => ({ createdAt: value }),
    cellRenderer:params=>dayjs(params.value).format("DD/MM/YY HH:mm")
  },
  {
    field: "updatedAt",
    headerName: "Updated Date",
    minWidth: 180,
    filter: 'agDateColumnFilter',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0]),
        );
    
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
    
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
    
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      minValidYear: 2023,
      inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      filterOptions: ['inRange'],
      defaultOption: 'inRange',
      maxNumConditions: 1,
      inRangeInclusive:true
    },
    getFilterObj: (value) => ({ updatedAt: value }),
    cellRenderer:params=>dayjs(params.value).format("DD/MM/YY HH:mm")
  },
  // {
  //   field: "isShared",
  //   headerName: "Shared",
  //   minWidth: 200,
  //   valueGetter: (params) => params.data.isShared ? "Yes" : "No",
  // },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 140,
    sortable: false,
    filter: false,
    cellRenderer: (params) => (
      <IconButton aria-label="delete">
        <Icon
          component={deleteIcon}
          onClick={(e) => handleDeleteClick(e, params.data.uuid)}
          fontSize="small"
        />
      </IconButton>
    ),
  },
];
function FixtureLandingPage({ onRowClick }) {
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const { selectedStore, selectedLocation, selectedSubLocation, selectedAssetTypeBatchCode, selectedAssetTypeCode} = useContext(DropDownSelectionContext);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const { externalGridRef } = useGridContext()

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input:{take: 100, skip: 0} },
    });

    const [fetchFixtures] = useLazyQuery(LIST_ALL_FIXTURES, {
        client: vmServiceClient,
    });

    const storeIdMap = React.useMemo(()=>getFlatIdMap(storeList?.stores?.results?.filter(store=>!store.isDeleted) || []),[storeList])
    const splitStoreIdMap = React.useCallback(()=>{
      const stores=[]
      const locations=[]
      const subLocations=[]
      const assetTypes=[]
      Object.values(storeIdMap)?.forEach(obj=>{
        switch(obj.__typename){
          case "Store":
            stores.push(obj)
            break;
          case "StoreLocation":
            locations.push(obj)
            break;
          case "SubLocation":
            subLocations.push(obj)
            break;
          case "AssetType":
            assetTypes.push(obj)
            break;
          default:
            break;
        }
      })
      return {stores, locations, subLocations, assetTypes}
    },[storeIdMap])
    const {stores, locations, subLocations, assetTypes} = React.useMemo(()=>splitStoreIdMap(),[storeIdMap])
    
    const [removeFixture, { loading: removing, error: removeError }] =
    useMutation(REMOVE_FIXTURE, {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: LIST_ALL_FIXTURES,
                variables: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    "sortBy": "updateAt",
                    "order": "DESC",
                },
                fetchPolicy: "network-only",
            },
        ],
        onCompleted: () => {
            // refetch(); // Ensure refetch is triggered after mutation
            if (externalGridRef) {
              externalGridRef.current?.refreshServerSide({purge:true});
            }
          }
    });
    const handleDeleteClick = async (e, fixtureId) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        try {
            await removeFixture({
                variables: { id: fixtureId },
            });
            await refetch();
        } catch (error) {
            console.error("Failed to remove fixture:", error);
        } finally {
            setIsLoading(false);
        }
    };

    
    return (
      
      <AgGrid
        columns={columnDefs(handleDeleteClick, storeIdMap, stores, locations, subLocations, assetTypes, externalGridRef)}
        allowSearch={true}
        readMethod={{
          fetchFn: fetchFixtures,
          inputParser: ({ startRow, endRow, filter, sortModel, searchCols, searchBy }) => {
            const sortObj = createSortByFromOrderModel(sortModel, columnDefs(handleDeleteClick, storeIdMap, stores, locations, subLocations, assetTypes, externalGridRef));
            // const searchCols = Object.keys(filter) || [];
            // const searchBy = searchCols.map((searchCol) => filter[searchCol]);
            return {
              limit: endRow - startRow,
              page: Math.floor(startRow / (endRow - startRow)) + 1,
              searchCols,
              searchBy,
              filter: {
                storeId: selectedStore?.id,
                locationId: selectedLocation?.id,
                subLocationId: selectedSubLocation?.id,
                subLocationTypeId: selectedAssetTypeBatchCode || undefined,
                assetTypeCode: selectedAssetTypeCode || undefined,
                isDeleted:false,
                ...filter,
              },
              sortBy: sortObj ? sortObj[0] : "updatedAt",
              order: sortObj ? sortObj[1] : "desc",
            };
          },
          resultsParser: ({ data }) => ({
            results: data?.findAllFixtures?.results,
            total: data?.findAllFixtures?.total,
          }),
        }}
        externalGridRef={externalGridRef}
        gridProps={{
          onRowClicked: (row) => {
            if (row.event.defaultPrevented) {
              return null;
            }
            onRowClick({ row: row?.data });
          },
        }}
        defaultColDefProp={{
          filter: "agTextColumnFilter",
          floatingFilter: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
          // suppressMenu: true,
          suppressAndOrCondition: true,
          maxNumConditions: 1,
        }}
      />
    );
}

export default FixtureLandingPage;


import React,{useContext} from 'react'
import AgGrid from 'components/MDGrid';
import { ReportsFilter } from '.';

function ReportTables({rowData,columnDefs}) {
  const {getStockReport,tabValue,startDate,endDate,selectedBrand,selectedStore,getSalesReport,key} = useContext(ReportsFilter)

  const fetchFn = () => {
    if (tabValue === 1) {
      return getSalesReport;
    } else if (tabValue === 3) {
      return getStockReport;
    }
  };
    // Determine the resultsParser dynamically based on the tabValue
    const resultsParser = (data) => {
      switch (tabValue) {
        case 1:
          return {
            results: data?.getSalesReports?.results || [],
            total: data?.getSalesReports?.total || 0,
          };
        case 3:
          return {
            results: data?.getStockReports?.results || [],
            total: data?.getStockReports?.total || 0,
          };
        default:
          return {
            results: [],
            total: 0,
          };
      }
    };
  return (
    <div
    className={
        "ag-theme-quartz-dark"
    }
    style={{ height: 500 }}
>
  {tabValue != 3 && tabValue != 1 ? ( <AgGrid
        queryData={rowData || []}
        columns={columnDefs}
        gridProps={{
          rowHeight:60,
          defaultColDef:{
            enableRowGroup:true,
            enablePivot:true,
            enableValue:true,
            floatingFilter: true,
            sortable: true,
            editable: false,
            filter: true,
            flex: 1,
            minWidth: 200,
          },
          pivotMode:false,
          sideBar:{
            toolPanels: ['columns', 'filters'], 
          }, 
          enableRangeSelection:true,
        }}  
    /> ): 
    (<AgGrid
    key={key}
    readMethod={{
      fetchFn: fetchFn(),
      inputParser: ({ startRow, endRow, filter, sortModel }) => {
        return ( {
          take: endRow - startRow,
          skip: startRow,
          startDate: startDate || null,
          endDate: endDate || null,
          searchCols: Object.keys(filter) || [],
          searchBy: Object.values(filter) || [],
          filter: {
            brandId: selectedBrand || null,
            storeId: selectedStore || null,
          },
        })
      },
      resultsParser: ({ data }) => resultsParser(data),
    }}
    columns={columnDefs}
    defaultColDefProp={{ filter: "agTextColumnFilter" , editable: false}}
  />)
    }
</div>
  )
}

export default ReportTables 

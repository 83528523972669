import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from "xlsx";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise"; // This includes all enterprise modules
import { Button } from "@mui/material";
import Loader from "components/MDLoader";

Object.defineProperty(String.prototype, "capitalize", {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false,
});

const ExcelToAgGrid = () => {
        const [rowData, setRowData] = useState([]); // Grid data
        const [columnDefs, setColumnDefs] = useState([]); // Column definitions
        const [file, setFile] = useState(null); // Selected Excel file
        const [defaultColDef] = useState({
            sortable: true,
            filter: "agSetColumnFilter",
            resizable: true,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            floatingFilter: true,
        });
        const inputRef = React.useRef();
        const [loading, setLoading] = useState(true);
        const user =
            localStorage.getItem("userDetails") &&
            localStorage.getItem("userDetails") !== "undefined" ?
            JSON.parse(localStorage.getItem("userDetails")) : {};
        console.log(...[user, "👀 [visualisation.js:37]: user"].reverse());

        // Function to fetch data from the backend (API call)
        const fetchDataFromServer = async() => {
                try {
                    const url =
                        process.env.REACT_APP_NODE_ENV === "development" ?
                        `${
              process.env.REACT_APP_API_BASE_URL
            }/report-service/import-report-excel?${
              !['admin', 'broadway-rm'].includes(user?.role)  ? `vendor=${user?.name}` : ""
            }`
          : `/report-service/import-report-excel`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }); // Fetch from your API endpoint
      const data = await response.json(); // Assuming the API returns JSON data
      console.log(...[data, "👀 [visualisation.js:36]: data"].reverse());
      if (data.length > 0) {
        const headers = Object.keys(data[0]?.order);
        console.log(
          ...[headers, "👀 [visualisation.js:39]: headers"].reverse()
        );
        // Set row data for ag-Grid
        const rowData = data
          .map((row) => (row && row?.order?.billNo ? { ...row?.order } : null))
          .filter((row) => row?.billNo);
        console.log(
          ...[rowData, "👀 [visualisation.js:57]: rowData"].reverse()
        );
        setRowData(rowData);
        // Set column definitions based on response data
        setColumnDefs(
          headers.map((header) => ({
            headerName: header?.capitalize(),
            field: header,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching data from the server:", error);
    } finally {
      setLoading(false);
    }
  };

  // Load server data initially when the component mounts
  useEffect(() => {
    fetchDataFromServer();
  }, []);

  // Function to handle file upload and read Excel locally
  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile); // Store the file for backend upload

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });

      const headers = worksheet[2]; // Get headers from row 3
      const rows = worksheet.slice(3); // Start data after headers

      // Set column definitions for ag-Grid
      setColumnDefs(
        headers.map((header) => ({
          headerName: header,
          field: header,
        }))
      );

      // Set row data for ag-Grid to show the local file data
      setRowData(
        rows.map((row) =>
          headers.reduce((acc, header, idx) => {
            acc[header] = row[idx] || "";
            return acc;
          }, {})
        )
      );
    };
    selectedFile && reader.readAsArrayBuffer(selectedFile);
  };

  // Function to submit file to the backend and then refetch data from the server
  const uploadFileToServer = async () => {
    setLoading(true);
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const url =
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/report-service/import-report-excel`
          : `/report-service/import-report-excel`;
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        alert("File uploaded successfully!");
        if (inputRef.current) {
          inputRef.current.value = ""; // Clear the file input field
        }
        fetchDataFromServer(); // Refetch data from the server after successful upload
      } else {
        alert("File upload failed!");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred while uploading the file.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div>
    {/* TODO: for now only rahul@broadwaylive will be able to upload folder for sync */}
      {user?.role === "admin" && user.userId === '01ae99c5-d6cf-4913-8dfa-fc36cf531edf' && (
        <>
          <input
            ref={inputRef}
            type="file"
            accept=".xlsx, .xls"
            onChange={onFileChange}
          />
          <Button onClick={uploadFileToServer} style={{ marginTop: "10px" }}>
            Upload to Server
          </Button>
        </>
      )}
      <div
        className="ag-theme-alpine"
        style={{ height: "85vh", width: "100%", marginTop: "20px" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDisplayType={"groupRows"}
          rowGroupPanelShow={"always"} // Show row group panel
          enableCharts={true} // Enable chart visualizations
          enableRangeSelection={true} // Enable range selection for charts
          animateRows={true} // Smooth transitions
          sideBar={{ toolPanels: ["columns", "filters"] }}
        />
      </div>
    </div>
  );
};

export default ExcelToAgGrid;
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import { dashboardServiceClient } from "graphql/client";
import { useState } from "react";
import { BRANDS_LIST } from "services/brand-service";

export default function SingleProductFilterForm({
  filters,
  setFilters,
  open,
  setOpen,
}) {
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const isAdmin = user?.role === "admin";

  const [localFilters, setLocalFilters] = useState(filters);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const {
    loading: brandLoading,
    data: brandData,
    error: brandError,
  } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      filter: isAdmin
        ? { isDeleted: false }
        : {
            username: user?.username,
            isDeleted: false,
          },
      take: 20,
      skip: 0,
    },
  });

  const brandOptions =
    !brandLoading && !brandError
      ? brandData?.brandlist?.results.filter(
          (brand) => brand.brandStatus === "Live"
        )
      : [];

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
      <DialogTitle>Select Product Filters</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel id="brand-label">Brand</InputLabel>
            <Select
              labelId="brand-label"
              id="brand-select"
              sx={{
                height: "40px",
                position: "relative",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7b809a !important",
                },
              }}
              value={localFilters.brandId}
              onChange={(e) =>
                setLocalFilters({ ...filters, brandId: e.target.value })
              }
              input={<OutlinedInput label="Brand" />}
            >
              <MenuItem value="">All</MenuItem>
              {brandLoading && <MenuItem>Loading...</MenuItem>}

              {brandError && <MenuItem>Error</MenuItem>}

              {!brandLoading &&
                !brandError &&
                (brandOptions || []).map((brand) => {
                  return <MenuItem value={brand.id}>{brand.name}</MenuItem>;
                })}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              sx={{
                height: "40px",
                position: "relative",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7b809a !important",
                },
              }}
              value={localFilters.status}
              onChange={(e) => {
                setLocalFilters({ ...localFilters, status: e.target.value });
              }}
              input={<OutlinedInput label="status" />}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="G_PENDING">Genysis Pending</MenuItem>
              <MenuItem value="G_VALIDATION_FAILED">
                Genysis Validation Failed
              </MenuItem>
              <MenuItem value="G_REJECTED">Genysis Rejected</MenuItem>
              <MenuItem value="G_FAILED">Genysis Failed</MenuItem>
              <MenuItem value="G_SYNCED">Genysis Synced</MenuItem>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="APPROVED">Approved</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
              <MenuItem value="PARSEFAILED">Parse Failed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="outlined"
          // style={{
          //   backgroundColor: "#FFFFFF",
          //   marginRight: "10px",
          //   border: "1px solid #E93165",
          //   borderRadius: "24px",
          //   color: "#E93165",
          // }}
          color={"black"}
          circular={true}
          onClick={handleClose}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="contained"
          // style={{
          //   background: "#E93165",
          //   borderRadius: "24px",
          //   color: "#fff",
          // }}
          color={"black"}
          circular={true}
          onClick={(e) => {
            setFilters(localFilters);
            setOpen(false);
          }}
        >
          Save
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

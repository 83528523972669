import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { BRANDS_LIST, GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import { GET_CATEGORIES } from "services/product-category-service";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { ADD_SINGLE_PRODUCT } from "services/product-service";
import { productServiceClient } from "graphql/client";
import useProductContext from "layouts/product/context/useProductContext";
import MDButton from "components/MDButton";

function CustomErrorTextField({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        color: "#F44335",
        marginTop: "3px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
        fontSize: "0.75rem",
        fontWeight: "300",
        lineHeight: "1.25",
        letterSpacing: "0.03333em",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default function AddSingleProductDetails({ setForm }) {
  const formRef = useRef();

  const { setNotification } = useNotification();
  const { productContext, setProductContext } = useProductContext();

  const [createProduct] = useMutation(ADD_SINGLE_PRODUCT, {
    client: productServiceClient,
  });

  const validationSchema = (attributeMaster) => {
    const attributesSchema = attributeMaster.reduce((acc, attribute) => {
      const isRequired = attribute.isMandatory;

      const isTextField = attribute.fieldType === "Open";

      acc[attribute.name] = isRequired
        ? isTextField
          ? Yup.string().required(`${attribute.name} is required`)
          : Yup.string()
              .required(`${attribute.name} is required`)
              .oneOf(
                attribute.masterValues,
                `${attribute.name} must be one of the allowed values ${attribute.masterValues}`
              )
        : isTextField
        ? Yup.string()
        : Yup.string().oneOf(
            attribute.masterValues,
            `${attribute.name} must be one of the allowed values ${attribute.masterValues}`
          );

      return acc;
    }, {});

    return Yup.object().shape({
      brand: Yup.string().required("Brand is required"),
      category: Yup.string().required("Category is required"),
      barCode: Yup.string().required("Barcode is required"),
      parentArticle: Yup.string().required("Parent Article is required"),
      msp: Yup.string().required("Standard Price is required"),
      listingPrice: Yup.string().required("Listing Price is required"),
      mrp: Yup.string().required("Maximum Price is required"),
      hsn: Yup.string().required("HSN is required"),
      taxRate: Yup.string().required("Tax Rate is required"),
      productImage: Yup.string()
        .url("Invalid URL")
        .required("Product Image URL is required"),
      variantImage: Yup.string()
        .url("Invalid URL")
        .required("Variant Image URL is required"),
      vendorArticleNumber: Yup.string().required(
        "Vendor Article Number is required"
      ),
      categoryAttributes: Yup.object().shape(attributesSchema),
    });
  };

  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const isAdmin = user?.role === "admin";

  const initialValues = (attributeMaster) => ({
    brand: "",
    category: "",
    barCode: "",
    parentArticle: "",
    msp: "",
    listingPrice: "",
    mrp: "",
    hsn: "",
    taxRate: "",
    productImage: "",
    variantImage: "",
    vendorArticleNumber: "",
    categoryAttributes: attributeMaster?.reduce((acc, attribute) => {
      acc[attribute.name] = "";

      return acc;
    }, {}),
  });

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const {
    loading: brandLoading,
    data: brandData,
    error: brandError,
  } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: 20,
      skip: 0,
      filter: isAdmin
        ? {
            isDeleted: false,
          }
        : {
            username: user?.username,
            isDeleted: false,
          },
    },
  });

  const brandOptions =
    !brandLoading && !brandError
      ? brandData.brandlist?.results.filter(
          (brand) => brand.brandStatus === "Live"
        )
      : [];

  const [
    fetchBrandCateogryMapping,
    { loading: categoryLoading, data: categoryData, error: categoryError },
  ] = useLazyQuery(GET_BRAND_CATEGORY_STORE, {
    client: dashboardServiceClient,
  });

  const categoryOptions =
    categoryData?.brandCategoryStores?.results?.map((category) => category) ||
    [];

  const [
    fetchCategoryAttributeMaster,
    {
      data: categoryAttribtueData,
      loading: categoryAttributeLoading,
      error: categoryAttributeError,
    },
  ] = useLazyQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
  });

  const attributeMaster = [];

  const defaultAttributeMaster = [
    { name: "Item Inventory mgmt", isMandatory: true, fieldType: "Open" },
    { name: "Price Mgmt", isMandatory: true, fieldType: "Open" },
    { name: "Manage expiry", isMandatory: true, fieldType: "Open" },
    { name: "Validity Mode", isMandatory: true, fieldType: "Open" },
    { name: "Validity Period", isMandatory: true, fieldType: "Open" },
    { name: "Design / Model", isMandatory: true, fieldType: "Open" },
    { name: "Color", isMandatory: true, fieldType: "Open" },
    { name: "Flavour / Variant", isMandatory: true, fieldType: "Open" },
    { name: "Size", isMandatory: true, fieldType: "Open" },
    { name: "Pack Type", isMandatory: true, fieldType: "Open" },
    { name: "Sub Brand", isMandatory: true, fieldType: "Open" },
    { name: "Sample Flag", isMandatory: true, fieldType: "Open" },
    { name: "Product type", isMandatory: true, fieldType: "Open" },
    { name: "Unit of Measurement", isMandatory: true, fieldType: "Open" },
  ];

  categoryAttribtueData?.categoryProduct.results?.forEach((category) => {
    category?.categoryProductAttribute?.forEach((categoryAttribute) => {
      attributeMaster.push({
        attributeId: categoryAttribute.id,
        name: categoryAttribute.masterAttribute.name,
        type: categoryAttribute.attributeType,
        isMandatory: categoryAttribute.isMandatory,
        attributeValues: categoryAttribute.values,
        masterId: categoryAttribute.masterAttribute.id,
        masterValues: categoryAttribute.masterAttribute.values,
        fieldType: categoryAttribute.masterAttribute.fieldType,
      });
    });
  });

  const combinedAttributeMaster = [
    ...attributeMaster,
    ...defaultAttributeMaster.filter((attribute) => {
      const isExisting = attributeMaster.find(
        (item) => item.name === attribute.name
      );

      return !isExisting;
    }),
  ];

  const formSubmitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsSubmittingForm(true);

    try {
      const data = await createProduct({
        variables: { data: { ...values, nsp: values.msp } },
      });

      setNotification({
        color: "success",
        isVisible: true,
        message: "Product created succesfully",
      });

      setProductContext({ ...productContext, addedSingleProduct: true });

      setForm(false);
    } catch (error) {
      const response = error.response;
      let errorMessage = error.message;

      if (response) {
        errorMessage = response.data.message;
      }

      setNotification({
        color: "error",
        isVisible: true,
        message: errorMessage,
      });
    } finally {
      setSubmitting(false);
      setIsSubmittingForm(false);
    }
  };

  console.log({ combinedAttributeMaster });

  return (
    <Grid>
      <Formik
        validationSchema={validationSchema(combinedAttributeMaster)}
        initialValues={initialValues(combinedAttributeMaster)}
        onSubmit={formSubmitHandler}
        innerRef={formRef}
      >
        {({ touched, errors, values, setFieldValue, handleSubmit }) => {
          useEffect(() => {
            if (isAdmin) return;

            if (!brandData || brandLoading || brandError) return;

            const userBrandId = brandData?.brandlist?.results?.[0]?.id;

            setFieldValue("brand", userBrandId);

            fetchBrandCateogryMapping({
              variables: {
                take: 200,
                skip: 0,
                filter: {
                  brand: { id: userBrandId },
                  isDeleted: false,
                  category: { isNodeItem: true },
                },
              },
            });
          }, [brandData, brandLoading, brandError]);

          return (
            <Form onSubmit={handleSubmit} id="add-product-details">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Brand</InputLabel>
                    <Field
                      disabled={!isAdmin}
                      as={Select}
                      name="brand"
                      label="Brand"
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("brand", event.target.value);
                        setFieldValue("category", "");
                        fetchBrandCateogryMapping({
                          variables: {
                            take: 200,
                            skip: 0,
                            filter: {
                              brand: { id: event.target.value },
                              isDeleted: false,
                              category: { isNodeItem: true },
                            },
                          },
                        });
                      }}
                      sx={{
                        height: "40px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7b809a !important",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                    >
                      {brandLoading ? (
                        <MenuItem disabled>Brands Loading...</MenuItem>
                      ) : (
                        brandOptions?.map((brand) => (
                          <MenuItem key={brand.id} value={brand.id}>
                            {brand.name}
                          </MenuItem>
                        ))
                      )}
                    </Field>

                    {touched.brand && errors.brand && (
                      <CustomErrorTextField color="error">
                        {errors.brand}
                      </CustomErrorTextField>
                    )}

                    {brandError && (
                      <CustomErrorTextField color="error">
                        Error Fetching Brands list
                      </CustomErrorTextField>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Field
                      name="category"
                      as={Select}
                      onChange={(e) => {
                        setFieldValue("category", e.target.value);
                        fetchCategoryAttributeMaster({
                          variables: {
                            listCategoryProductFilter: {
                              take: 100,
                              skip: 0,
                              filter: {
                                id: e.target.value,
                                isDeleted: false,
                              },
                              orderby: { isDeleted: "ASC", updatedAt: "DESC" },
                            },
                          },
                        });
                      }}
                      label="Category"
                      error={touched.category && Boolean(errors.category)}
                      disabled={!values.brand}
                      sx={{
                        height: "40px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7b809a !important",
                        },
                      }}
                      IconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                    >
                      {!values.brand ? (
                        "Please Select a Brand First"
                      ) : categoryLoading ? (
                        <MenuItem disabled>Loading Categories... </MenuItem>
                      ) : (
                        categoryOptions.map(({ category }) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.title}
                          </MenuItem>
                        ))
                      )}
                    </Field>

                    {touched.category && errors.category && (
                      <CustomErrorTextField>
                        {errors.category}
                      </CustomErrorTextField>
                    )}

                    {categoryError && (
                      <CustomErrorTextField>
                        Error Loading categories
                      </CustomErrorTextField>
                    )}
                  </FormControl>
                </Grid>

                {categoryAttributeLoading ? (
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  combinedAttributeMaster.map((attribute) => {
                    if (attribute?.fieldType === "Open") return <></>;

                    return (
                      <Grid item xs={12} key={attribute.attributeId}>
                        <FormControl fullWidth>
                          <InputLabel>{attribute.name}</InputLabel>
                          <Field
                            error={Boolean(
                              errors.categoryAttributes?.[attribute.name]
                            )}
                            onChange={(e) => {
                              setFieldValue("categoryAttributes", {
                                ...values.categoryAttributes,
                                [attribute.name]: e.target.value,
                              });
                            }}
                            value={values.categoryAttributes?.[attribute.name]}
                            as={Select}
                            name={`attributes[${attribute.attributeId}]`}
                            label={attribute.name}
                            fullWidth
                            sx={{
                              height: "40px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#7b809a !important",
                              },
                            }}
                            IconComponent={() => (
                              <ArrowDropDownIcon
                                style={{ marginRight: "18px" }}
                              />
                            )}
                          >
                            {attribute.masterValues.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Field>

                          {errors?.categoryAttributes?.[attribute.name] && (
                            <CustomErrorTextField>
                              {errors?.categoryAttributes?.[attribute.name]}
                            </CustomErrorTextField>
                          )}
                        </FormControl>
                      </Grid>
                    );
                  })
                )}

                {combinedAttributeMaster.map((attribute) => {
                  if (attribute.fieldType !== "Open") return <></>;
                  return (
                    <Grid item xs={6}>
                      <Field
                        name={attribute.name}
                        value={values.categoryAttributes[attribute.name]}
                        as={TextField}
                        onChange={(e) => {
                          setFieldValue("categoryAttributes", {
                            ...values.categoryAttributes,
                            [attribute.name]: e.target.value,
                          });
                        }}
                        variant="outlined"
                        label={attribute.name}
                        fullWidth
                        error={Boolean(
                          errors.categoryAttributes?.[attribute.name]
                        )}
                        sx={{
                          height: "40px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#7b809a !important",
                          },
                        }}
                      />
                      {errors?.categoryAttributes?.[attribute.name] && (
                        <CustomErrorTextField>
                          {errors?.categoryAttributes?.[attribute.name]}
                        </CustomErrorTextField>
                      )}
                    </Grid>
                  );
                })}

                <Grid item xs={12}>
                  <Field
                    name="barCode"
                    as={TextField}
                    variant="outlined"
                    label="Barcode"
                    fullWidth
                    error={touched.barCode && Boolean(errors.barCode)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.barCode && errors.barCode && (
                    <CustomErrorTextField>
                      {errors.barCode}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="parentArticle"
                    as={TextField}
                    variant="outlined"
                    label="Parent Article"
                    fullWidth
                    error={
                      touched.parentArticle && Boolean(errors.parentArticle)
                    }
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.parentArticle && errors.parentArticle && (
                    <CustomErrorTextField>
                      {errors.parentArticle}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Field
                    name="msp"
                    as={TextField}
                    variant="outlined"
                    label="Standard Price"
                    fullWidth
                    error={touched.msp && Boolean(errors.msp)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.msp && errors.msp && (
                    <CustomErrorTextField>{errors.msp}</CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Field
                    name="listingPrice"
                    as={TextField}
                    variant="outlined"
                    label="Listing Price"
                    fullWidth
                    error={touched.listingPrice && Boolean(errors.listingPrice)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.listingPrice && errors.listingPrice && (
                    <CustomErrorTextField>
                      {errors.listingPrice}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Field
                    name="mrp"
                    as={TextField}
                    variant="outlined"
                    label="Maximum Price"
                    fullWidth
                    error={touched.mrp && Boolean(errors.mrp)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.mrp && errors.mrp && (
                    <CustomErrorTextField>{errors.mrp}</CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="hsn"
                    as={TextField}
                    variant="outlined"
                    label="HSN"
                    fullWidth
                    error={touched.hsn && Boolean(errors.hsn)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.hsn && errors.hsn && (
                    <CustomErrorTextField>{errors.hsn}</CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="taxRate"
                    as={TextField}
                    variant="outlined"
                    label="Tax Rate"
                    fullWidth
                    error={touched.taxRate && Boolean(errors.taxRate)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.taxRate && errors.taxRate && (
                    <CustomErrorTextField>
                      {errors.taxRate}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="productImage"
                    as={TextField}
                    variant="outlined"
                    label="Product Image URL"
                    fullWidth
                    error={touched.productImage && Boolean(errors.productImage)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.productImage && errors.productImage && (
                    <CustomErrorTextField>
                      {errors.productImage}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="variantImage"
                    as={TextField}
                    variant="outlined"
                    label="Variant Image URL"
                    fullWidth
                    error={touched.variantImage && Boolean(errors.variantImage)}
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.variantImage && errors.variantImage && (
                    <CustomErrorTextField>
                      {errors.variantImage}
                    </CustomErrorTextField>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="vendorArticleNumber"
                    as={TextField}
                    variant="outlined"
                    label="Vendor Article Number"
                    fullWidth
                    error={
                      touched.vendorArticleNumber &&
                      Boolean(errors.vendorArticleNumber)
                    }
                    sx={{
                      height: "40px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7b809a !important",
                      },
                    }}
                  />
                  {touched.vendorArticleNumber &&
                    errors.vendorArticleNumber && (
                      <CustomErrorTextField>
                        {errors.vendorArticleNumber}
                      </CustomErrorTextField>
                    )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          mt: "20px",
        }}
        gap={2}
      >
        <MDButton
          variant="outlined"
          // style={{
          //   backgroundColor: "#FFFFFF",
          //   marginRight: "10px",
          //   border: "1px solid #E93165",
          //   borderRadius: "24px",
          //   color: "#E93165",
          // }}
          color="black"
          circular={true}
          onClick={() => {
            setForm(false);
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="contained"
          // style={{
          //   background: "#E93165",
          //   borderRadius: "24px",
          //   color: "#fff",
          // }}
          color="black"
          circular={true}
          onClick={() => {
            formRef.current.submitForm();
          }}
          disabled={isSubmittingForm}
        >
          {isSubmittingForm ? "Loading..." : "Save"}
        </MDButton>
      </Grid>
    </Grid>
  );
}
